module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-transition-link/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Star Kitchen","short_name":"Star Kitchen","start_url":"/","background_color":"#000","theme_color":"#000","display":"minimal-ui","icon":"src/images/star-kitchen-icon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"c56d75328aaab6d127d89cd9b9caeda0"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-5DXP3L9","includeInDevelopment":false,"defaultDataLayer":{"type":"function","value":"function () {\n          return {}\n        }"}},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
